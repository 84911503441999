import Swiper from "swiper"
import { find,findAll } from "utils/dom"

export default function () {
	const depoimento = find("[js-page='depoimento']")
	if (!depoimento) return
        

    


      



        function modalCases(){

                const bodyElement = document.querySelector('body');
                const htmlElement = document.documentElement;
                const closeBtn = document.querySelector("#close");
                const closeBtn_mobal = document.querySelector("#close_sm"); 
                const modal = document.getElementById("myModal_conteudo");

                if(closeBtn_mobal) {
                        closeBtn_mobal.addEventListener('click', () => {
                             modal.style.display = "none";
                             bodyElement.style.overflow = 'auto';
                             htmlElement.style.overflow = 'auto';
                             });     
     
                }

                if(closeBtn) {
                   closeBtn.addEventListener('click', () => {
                        modal.style.display = "none";
                        bodyElement.style.overflow = 'auto';
                        htmlElement.style.overflow = 'auto';
                        });     

                }
                

        }

       

        
        




     const destinos_depoimentos = findAll('.js-destino-depoimentos')


  


    destinos_depoimentos.forEach(item => {
        item.addEventListener('click', () => {

                
          

          const idDestino = item.dataset.destino;
          const titlePt = item.querySelector('p').innerHTML;

          
          
          const modal_conteudo = document.getElementById("myModal_conteudo");
          const bodyElement = document.querySelector('body');
                      const htmlElement = document.documentElement;

          

          console.log("AQUI2", titlePt)
          modal_conteudo.style.display = "block";
                      modal_conteudo.scrollTop = 0;

         
                

                

                

                const conteudoModal = `

                
                        <div class="fixed inset-0 flex items-center justify-center">
                <div class="max-w-[800px] max-h-[300px] overflow-y-auto bg-white">
                        <div class="relative">
                        <button id="close_sm" class="mt-24 ml-24">
                                <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect x="0.5" y="0.5" width="55" height="55" rx="27.5" stroke="black" stroke-opacity="0.2"/>
                                <path d="M21.75 21.75L34.25 34.25" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M21.75 34.25L34.25 21.75" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                        </button>
                        </div>
                        <div class="relative h-full flex justify-center px-24 py-24">
                        <p class="text-[#000532] max-w-max text-title-7">
                                ${titlePt}
                        </p>
                        </div>
                </div>
                </div>
                
                

                        

        
                

               
          
                
                      


                `;
            
                
                const modal = document.getElementById("myModal_conteudo");
                modal.querySelector(".modal-content_depoimento").innerHTML = conteudoModal;

                modalCases()

                
              
         })
                                   




          

          


          

      
          
         


          
          
        });


}

    

   