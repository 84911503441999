import Swiper from "swiper"
import { find,findAll } from "utils/dom"

export default function () {
	const Home = find("[js-page='home']")
	if (!Home) return



	function modalCases(){

		const bodyElement = document.querySelector('body');
		const htmlElement = document.documentElement;
		const closeBtn = document.querySelector("#close");
		const closeBtn_mobal = document.querySelector("#close_sm"); 
		const modal = document.getElementById("myModal_conteudo");

		if(closeBtn_mobal) {
				closeBtn_mobal.addEventListener('click', () => {
					 modal.style.display = "none";
					 bodyElement.style.overflow = 'auto';
					 htmlElement.style.overflow = 'auto';
					 });     

		}

		if(closeBtn) {
		   closeBtn.addEventListener('click', () => {
				modal.style.display = "none";
				bodyElement.style.overflow = 'auto';
				htmlElement.style.overflow = 'auto';
				});     

		}
		

    }

    const destinos_depoimentos = findAll('.js-destino-depoimentos')


    destinos_depoimentos.forEach(item => {
    item.addEventListener('click', () => {

		
  

	const idDestino = item.dataset.destino;
	const titlePt = item.querySelector('p').innerHTML;

	
	
	const modal_conteudo = document.getElementById("myModal_conteudo");
	const bodyElement = document.querySelector('body');
	const htmlElement = document.documentElement;

	

	console.log("AQUI2", titlePt)
	modal_conteudo.style.display = "block";
				modal_conteudo.scrollTop = 0;

			const conteudoModal = `

			
					<div class="fixed inset-0 flex items-center justify-center">
			<div class="max-w-[800px] max-h-[300px] overflow-y-auto bg-white">
					<div class="relative">
					<button id="close_sm" class="mt-24 ml-24">
							<svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
							<rect x="0.5" y="0.5" width="55" height="55" rx="27.5" stroke="black" stroke-opacity="0.2"/>
							<path d="M21.75 21.75L34.25 34.25" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
							<path d="M21.75 34.25L34.25 21.75" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
							</svg>
					</button>
					</div>
					<div class="relative h-full flex justify-center px-24 py-24">
					<p class="text-[#000532] max-w-max text-title-7">
							${titlePt}
					</p>
					</div>
			</div>
			</div>
			
			`;
		
			
			const modal = document.getElementById("myModal_conteudo");
			modal.querySelector(".modal-content_depoimento").innerHTML = conteudoModal;

			modalCases()

			
		
    })
						   
    });


	


	
	const swiperTags = new Swiper(".swiper-tags",{
		slidesPerView: 'auto',
		spaceBetween: 48,
		preloadImages: false,
	

		

		navigation: {
			nextEl: '#secaoServicos .swiper-button-next-tags',
			prevEl: '#secaoServicos .swiper-button-prev-tags',
		},

		breakpoints: {
			220: {
				centeredSlides: true,
				
			},
			1024: {
				centeredSlides: false
			}
		}
		


	})

	const slide_1 = document.querySelector(".slide-1").classList.add('active')




	const buttons = document.querySelectorAll('.swiper-slide button');
	buttons.forEach((button) => {
	button.addEventListener('click', () => {
	
		buttons.forEach((otherButton) => {
		otherButton.classList.remove('active');
		});

		
		button.classList.add('active');
	});
	});



	function modalCases_fundament(){

		const bodyElement = document.querySelector('body');
		const htmlElement = document.documentElement;
		const closeBtn_mobal = document.querySelector("#close_fundamento_home"); 
		const modal = document.getElementById("myModal_fundamento_home");

		if(closeBtn_mobal) {

				
				closeBtn_mobal.addEventListener('click', () => {
					 modal.style.display = "none";
					 bodyElement.style.overflow = 'auto';
					 htmlElement.style.overflow = 'auto';
					 });     

		}

		
		

    }


	
	const destinos_fundamento_home = findAll('.js-destino-fundamento-home')


    destinos_fundamento_home.forEach(item => {
    item.addEventListener('click', () => {

		
  

	const idDestino = item.dataset.destino;
	const titlePt = item.querySelector('p').innerHTML;

	
	
	const modal_conteudo = document.getElementById("myModal_fundamento_home");
	const bodyElement = document.querySelector('body');
	const htmlElement = document.documentElement;

	

	
	modal_conteudo.style.display = "block";
				modal_conteudo.scrollTop = 0;

			const conteudoModal = `

			
					<div class="fixed inset-0 flex items-center justify-center">
			<div class="max-w-[800px] max-h-[300px] overflow-y-auto bg-white">
					<div class="relative">
					<button id="close_fundamento_home" class="mt-24 ml-24">
							<svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
							<rect x="0.5" y="0.5" width="55" height="55" rx="27.5" stroke="black" stroke-opacity="0.2"/>
							<path d="M21.75 21.75L34.25 34.25" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
							<path d="M21.75 34.25L34.25 21.75" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
							</svg>
					</button>
					</div>
					<div class="relative h-full flex justify-center px-24 py-24">
					<p class="text-[#000532] max-w-max text-title-7">
							${titlePt}
					</p>
					</div>
			</div>
			</div>
			
			`;
		
			
			const modal = document.getElementById("myModal_fundamento_home");
			modal.querySelector(".modal-content_fundamento_home").innerHTML = conteudoModal;

			modalCases_fundament()

			
		
    })
						   
    });
	




	let botaozinho = document.querySelector('#scrollRightBtn')

	console.log("AQUI EU",botaozinho)

	const swiperCrrossel0 = new Swiper(".swiperCarrossel0", {
		slidesPerView: 1, 
		spaceBetween: 2,
		slideToClickedSlide: false,
		preloadImages: false,
		centeredSlides: false, 
		loop:false,
		pagination: {
		  el: ".swiper-paginatio",
		  type: "fraction",
		},
		speed: 1500,
		on: {
		  slideChange: function () {
			this.pagination.update();
		  },
		},
	});

	const swiperclients = new Swiper(".swiperClientes",{
		slidesPerView: 4,
		spaceBetween: 24,
		preloadImages: false,
		centeredSlides: false,
		

		breakpoints: {
			
			
			220: {
				slidesPerView: 2.5,
				centeredSlides: true,
				spaceBetween: 0,
			},
			640: {
				slidesPerView: 2.6,
				centeredSlides: false,
				spaceBetween: 0,
				
			},
			820: {
				slidesPerView: 4,
				centeredSlides: false,
				
				
				
			},
			
		}

	
		


	})

	const swiperPessoas = new Swiper(".swiperPessoas", {
		slidesPerView: 4,
		spaceBetween: 2,
		slideToClickedSlide:false,
		preloadImages: false,
		noSwiping:true,
		a11y: false,
		
		
		autoplay: false,
	
		pagination: {
			el: window.innerWidth <= 1024 ? ".swiper-pagination-pessoas-mobile" : undefined,
			type: "fraction",
		},
		
		navigation: {
			nextEl: '#scrollRightBtn_fundamentos', 
			prevEl: '#scrollLeftBtn_fundamentos', 
	  },
	
	
		breakpoints: {
			
			220: {
				slidesPerView: 1.2,
				centeredSlides: false,
				spaceBetween: 2,
			},
			420: {
				slidesPerView: 1.5,
				centeredSlides: false,
				spaceBetween: 2,
			},
			640: {
				slidesPerView: 1.6,
				centeredSlides: false,
				spaceBetween: 2,
			},
			820: {
				slidesPerView: 3,
				centeredSlides: false,
				spaceBetween: 2,
				
				
			},
			1024: {
				slidesPerView: 4,
				centeredSlides: false,
				spaceBetween: 2,

				pagination: {
					el: ".swiper-pagination-pessoas",
					type: "fraction",
				  },
				
			  },
		}
	
	})
	

	const swiperCarrossel2 = new Swiper(".swiperCarrossel2", {
		slidesPerView: 1, 
		
		slideToClickedSlide:true,
		preloadImages: false,
		autoHeight: true,

		noSwipingClass: "swiperCarrossel2",


		
     	centeredSlides: true,
      	spaceBetween: 30,


		
			
      
      

	});

	  document
		.querySelector('.slide-1')
		.addEventListener('click', function (e) {
			e.preventDefault();
			console.log("teste")
			swiperCarrossel2.slideTo(0,0);
		});
		document
		.querySelector('.slide-2')
		.addEventListener('click', function (e) {
			e.preventDefault();
			console.log("teste")
			swiperCarrossel2.slideTo(1,0);
		});
		document
      	.querySelector('.slide-3')
      	.addEventListener('click', function (e) {
        e.preventDefault();
        swiperCarrossel2.slideTo(2,0);
        });
		



			const swiperCrrossel3 = new Swiper(".swiperCarrossel3", {
				slidesPerView: 1, // Número de cards visíveis ao mesmo tempo
				spaceBetween: 16,
				slideToClickedSlide:true,
				preloadImages: false,
				


				pagination: {
					el: window.innerWidth <= 1024 ? ".swiper-pagination_contato" : undefined,
					type: "fraction",
			  },
		
		
				
		
				
				navigation: {
					nextEl: '#scrollRightBtn2', 
					prevEl: '#scrollLeftBtn2', 
				},
		
				breakpoints: {
					
					640: {
						slidesPerView: 1,
						centeredSlides: true,
						spaceBetween: 16,
					},
					768:{
						slidesPerView: 2,
						centeredSlides: false,
						spaceBetween: 16,
					},

					800:{
						slidesPerView: 2,
						centeredSlides: false,
						spaceBetween: 16,
					},
					820: {
						slidesPerView: 3,
						centeredSlides: false,
						spaceBetween: 16,
					},
					940: {
						slidesPerView: 3,
						centeredSlides: false,
						spaceBetween: 16,
					},
					1024: {
						slidesPerView: 3,
						centeredSlides: false,
						spaceBetween: 16,
						
					},
					1326: {
						slidesPerView: 3,
						centeredSlides: false,
						spaceBetween: 16,
						pagination: {
							el: ".swiper-pagination2_contato",
							type: "fraction",
					},
					}
		
				}
				
		
				
			
		
		
		
			});

			

			const swiperCarrosel5 = new Swiper(".swiperCarrosel5", {
				slidesPerView: 1, // Número de cards visíveis ao mesmo tempo
				spaceBetween: 32,
				loop:true,
				preloadImages: false,
				watchSlidesProgress: true,
				
			
		
			});

			const swiperCrrosselCases = new Swiper(".swiperCrrosselCases", {
				slidesPerView: 1.2,
				preloadImages: false,
				
				loop:true,
				spaceBetween: 12,
				watchSlidesProgress: true,
				grabCursor: true,
				slideToClickedSlide:true,
				centeredSlides:true,

				// centeredSlides: true,
				centeredSlidesBounds:true,
				// centerInsufficientSlides:true,
				// centeredSlides:true,
				
				// autoHeight: true,
				
				breakpoints: {
					
					1024: {
						slidesPerView: 'auto',
						// centeredSlides: true,
						spaceBetween: 30,
						centeredSlides: false,
						// grabCursor: true,

					},	

				

			   },

			   thumbs: {
					swiper: swiperCarrosel5,
				},


				navigation: {
					nextEl: '#cases #scrollRightBtn3', 
					prevEl: '#cases #scrollLeftBtn3', 
				},

			});


			// swiperCarrosel5.controller.control = swiperCrrosselCases;
			// swiperCrrosselCases.controller.control = swiperCarrosel5;



			const swiperCarrosel4 = new Swiper(".swiperCarrosel4", {
				slidesPerView: 1, // Número de cards visíveis ao mesmo tempo
				spaceBetween: 16,
				slideToClickedSlide:true,
				preloadImages: false,
				// autoHeight: true,
				
				
				speed: 1500, 
		
				


				pagination: {
					el: window.innerWidth <= 1024 ? ".swiper-pagination_contato" : undefined,
					type: "fraction",
			  },
		
		
				
		
				
				navigation: {
					nextEl: '#scrollRightBtn2', 
					prevEl: '#scrollLeftBtn2', 
				},
		
				breakpoints: {
					
					640: {
						slidesPerView: 1.6,
						centeredSlides: false,
						spaceBetween: 32,
					},
					820: {
						slidesPerView: 3,
						centeredSlides: false,
						spaceBetween: 32,
					},
					1024: {
						slidesPerView: 3,
						centeredSlides: false,
						spaceBetween: 32,
						pagination: {
								el: ".swiper-pagination1_contato",
								type: "fraction",
						},
					},
		
				}
				
			});




			
			
			



			
		


}