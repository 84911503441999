import Swiper from "swiper"
import { find,findAll } from "utils/dom"

export default function () {
	const about = find("[js-page='about']")
	if (!about) return



	const swiperTags = new Swiper(".swiper-tags_about",{
        slidesPerView: 'auto',
        spaceBetween: 48,

        
    
        navigation: {
            nextEl: '.swiper-button-next-tags-about',
            prevEl: '.swiper-button-prev-tags-about',
        },
    
    
    })
    
    const slide_1 = document.querySelector(".slide_about-1").classList.add('active_about')
    
    
    const buttons = document.querySelectorAll('.swiper-slide button');
    buttons.forEach((button) => {
      button.addEventListener('click', () => {
       
        buttons.forEach((otherButton) => {
          otherButton.classList.remove('active_about');
        });
    
        
        button.classList.add('active_about');
      });
    });

    const swiperAboutClick = new Swiper(".swiperAboutClick", {
		slidesPerView: 1, 
		
		slideToClickedSlide:true,
		autoHeight: true,
		
		
      centeredSlides: true,
      spaceBetween: 30,


	});

	  document
		.querySelector('.slide_about-1')
		.addEventListener('click', function (e) {
			e.preventDefault();
			console.log("teste")
			swiperAboutClick.slideTo(0,0);
		});
		document
		.querySelector('.slide_about-2')
		.addEventListener('click', function (e) {
			e.preventDefault();
			console.log("teste")
			swiperAboutClick.slideTo(1,0);
		});
		document
      .querySelector('.slide_about-3')
      .addEventListener('click', function (e) {
        e.preventDefault();
        swiperAboutClick.slideTo(2,0);
        });
		


        const swiperCarrosel6 = new Swiper(".swiperCarrosel6", {
            slidesPerView: 1, // Número de cards visíveis ao mesmo tempo
            spaceBetween: 35,
            slideToClickedSlide:true,
            autoHeight: true,
            
            
            
            
            speed: 1500, 
        
         

            pagination: {
                el: '.swiper-pagination_about',
                clickable: true,
              },
        
            
        
            
            navigation: {
                nextEl: '#scrollRightBtnAbout', 
                prevEl: '#scrollLeftBtnAbout', 
            },

            on: {
              init: function () {
                  // Verifica o número de slides após a inicialização
                  if (this.slides.length == 1) {
                      // Esconde os botões de navegação se houver apenas um slide
                      // document.querySelector('#scrollRightBtnAbout').style.display = 'none';
                      // document.querySelector('#scrollLeftBtnAbout').style.display = 'none';

                      document.querySelectorAll(".hideItem").forEach(function(element) {
                        element.style.display = 'none';
                    });
                    
                  }
              }
          }
        
        
        });


        function modalCases_fundament(){

          const bodyElement = document.querySelector('body');
          const htmlElement = document.documentElement;
          const closeBtn_mobal = document.querySelector("#close_fundamento_home"); 
          const modal = document.getElementById("myModal_fundamento_about");
      
          if(closeBtn_mobal) {
      
              
              closeBtn_mobal.addEventListener('click', () => {
                 modal.style.display = "none";
                 bodyElement.style.overflow = 'auto';
                 htmlElement.style.overflow = 'auto';
                 });     
      
          }
      
          
          
      
          }
      
      
        
        const destinos_fundamento_about = findAll('.js-destino-fundamento-about')
      
      
          destinos_fundamento_about.forEach(item => {
          item.addEventListener('click', () => {
      
          
        
      
        const idDestino = item.dataset.destino;
        const titlePt = item.querySelector('p').innerHTML;
      
        
        
        const modal_conteudo = document.getElementById("myModal_fundamento_about");
        const bodyElement = document.querySelector('body');
        const htmlElement = document.documentElement;
      
        
      
        
        modal_conteudo.style.display = "block";
              modal_conteudo.scrollTop = 0;
      
            const conteudoModal = `
      
            
                <div class="fixed inset-0 flex items-center justify-center">
            <div class="max-w-[800px] max-h-[300px] overflow-y-auto bg-white">
                <div class="relative">
                <button id="close_fundamento_home" class="mt-24 ml-24">
                    <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="0.5" y="0.5" width="55" height="55" rx="27.5" stroke="black" stroke-opacity="0.2"/>
                    <path d="M21.75 21.75L34.25 34.25" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M21.75 34.25L34.25 21.75" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </button>
                </div>
                <div class="relative h-full flex justify-center px-24 py-24">
                <p class="text-[#000532] max-w-max text-title-7">
                    ${titlePt}
                </p>
                </div>
            </div>
            </div>
            
            `;
          
            
            const modal = document.getElementById("myModal_fundamento_about");
            modal.querySelector(".modal-content_fundamento_about").innerHTML = conteudoModal;
      
            modalCases_fundament()
      
            
          
          })
                     
          });

        
    


}