import Swiper from 'swiper'
import { find, findAll } from 'utils/dom'

export default function () {
	const cases = find("[js-page='cases']")
	if (!cases) return

	//
	let ids = []

	const btnRight = find('#scrollRightBtnAbout_modal_casses')
	const btnLeft = find('#scrollLeftBtnAbout_modal_casses')
	const modal = document.getElementById('myModal')
	function modalCases() {
		const bodyElement = document.querySelector('body')
		const htmlElement = document.documentElement
		const closeBtn = document.querySelector('#close')
		const closeBtn_mobal = document.querySelector('#close_sm')
		const modal = document.getElementById('myModal')

		if (closeBtn_mobal) {
			closeBtn_mobal.addEventListener('click', () => {
				modal.style.display = 'none'
				bodyElement.style.overflow = 'auto'
				htmlElement.style.overflow = 'auto'
			})
		}

		if (closeBtn) {
			closeBtn.addEventListener('click', () => {
				modal.style.display = 'none'
				bodyElement.style.overflow = 'auto'
				htmlElement.style.overflow = 'auto'
			})
		}
	}

	function getModalInfo(id) {
              

                const bodyElement = document.querySelector('body')
                const htmlElement = document.documentElement
              
                const modal = document.getElementById('myModal')
                const modalContent = modal.querySelector('.modal-content')
                const loading = modal.querySelector('#img-loading-id')

                console.log(loading)
                if(loading){
                        loading.style.display= 'block'
                }
                // modalContent.innerHTML = ``

              
                
		fetch(`/api/cases/${id}`)
			.then((response) => response.json())
			.then((data) => {

                                const modal = document.getElementById('myModal')
                                const loading = modal.querySelector('#img-loading-id')
                                
                              


				// const VarResults = data.results
				// 	.map((item) => {
				// 		return ` <div class="mt-16 mb-16 border px-16 py-16 border-[#f2f2f4]">
                                //                                 <p class="text-[#000532] mb-8 text-headline-2">${item.title}</p>
                                //                                 <div class="text-neutral-60 text-paragraph-3">${item.description}</div>
                                //                         </div>`
				// 	        }).join('')

                                const VarResults = data.results.length > 0 
                                        ? data.results.map((item) => {
                                                return `<div class="mt-16 mb-16 border px-16 py-16 border-[#f2f2f4]">
                                                        <p class="text-[#000532] mb-8 text-headline-2">${item.title}</p>
                                                        <div class="text-neutral-60 text-paragraph-3">${item.description}</div>
                                                        </div>`;
                                        }).join('')
                                        : ' ';


				// const Varobjectives = data.objectives
				// 	.map((item) => {
				// 		return ` <div class="mt-16 mb-16 border px-16 py-16 border-[#f2f2f4]">
                                //                         <p class="text-[#000532] mb-8 text-headline-2">${item.title}</p>
                                //                         <div class="text-neutral-60 text-paragraph-3">${item.description}</div>
                                //                         </div>`
				// 	        }).join('')

                                const Varobjectives = data.objectives.length > 0 
                                        ? data.objectives.map((item) => {
                                                return `<div class="mt-16 mb-16 border px-16 py-16 border-[#f2f2f4]">
                                                        <p class="text-[#000532] mb-8 text-headline-2">${item.title}</p>
                                                        <div class="text-neutral-60 text-paragraph-3">${item.description}</div>
                                                        </div>`;
                                        }).join('')
                                        : ' ';

				const conteudoModal = `

<div class="absolute md:hidden py-24 mr-24 right-0 top-0">
              <button id="close" class="relative">
                      <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <rect x="0.5" y="0.5" width="55" height="55" rx="27.5" stroke="white" stroke-opacity="0.2"/>
                              <path d="M21.75 21.75L34.25 34.25" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
                              <path d="M21.75 34.25L34.25 21.75" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
                      </svg>
                      
              </button>

</div>

      <div class="absolute md:hidden md2:hidden top-0 filter-imagens-ornamento pointer-events-none overflow-hidden right-0">
              <img src="/static/img/banners/GroupCases2.webp" alt="">
      </div>

      
      <div class="absolute md:hidden md2:hidden top-3/4 filter-imagens-ornamento -translate-y-3/4 overflow-hidden right-0">
              <img src="/static/img/banners/GroupCases01.webp " alt="">
      </div>

      

      

      <div class="conteudo-meio-modal md:max-w-full dimencioncard relative bg-white h-full py-96 px-96 sm:px-8 overflow-y-auto">

              <div class="absolute hidden md:block py-24 mr-24 right-0 top-0">
              <button id="close_sm">
                      <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <rect x="0.5" y="0.5" width="55" height="55" rx="27.5" stroke="black" stroke-opacity="0.2"/>
                      <path d="M21.75 21.75L34.25 34.25" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M21.75 34.25L34.25 21.75" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
                      </svg>
                      
              </button>
      
              </div>

              <div class="hidden md:block md:mb-64 flex items-end ">

              <div class="max-w-full   z-0  overflow-hidden max-h-[823px]">
                      <div class="swiper-cases">
                      <div class="swiper-wrapper ">
                      
      
                              <div class="swiper-slide overflow-hidden">
                              <div class="before-degrade-imagem  overflow-hidden">
                                      <img loading="lazy" class="w-full h-full object-cover" src="${data.image}" alt="Imagem fundadora 01">
                              </div>			
                              
                              </div>
                              

                              
                              
      
                      </div>
      
                      </div>

                      

              </div>

              <div class="flex gap-24 mt-32 ">

              
                      <button id="scrollLeftBtnAbout_modal_casses">
                      <svg class="w-[22px] h-[22px] " viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M12.833 7.33341L9.16634 11.0001L12.833 14.6667" stroke="currentColor" stroke-opacity="0.7" stroke-linecap="round" stroke-linejoin="round"/>
                      </svg>
                      
                              
                      </button>

              
                      

                      <button id="scrollRightBtnAbout_modal_casses">
                      <svg class="w-[22px] h-[22px] rotate-180 " viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M12.833 7.33341L9.16634 11.0001L12.833 14.6667" stroke="currentColor" stroke-opacity="0.7" stroke-linecap="round" stroke-linejoin="round"/>
                      </svg>
                              
                      </button>

              

              

                      
                      
      
                      

              </div>

              
              

              </div>

              <div class="max-w-[712px]    text-start">
              <div class="mb-64">
                      <p class="text-[#3D92CC] mb-8 text-caps-2  leading-normal" >case</p>
                      <h1 class="text-[#000532] mb-24 text-title-1">${data.title}</h1>
                      <p class="text-neutral-60 text-paragraph-2">${data.description}</p>
              </div>
              <div class="mb-64">
                      <p class="text-[#3D92CC] mb-6 text-caps-2  leading-normal" >${Varobjectives === ' ' ? " " : "objetivos"}</p>
                      <hr class="${Varobjectives === ' ' ? 'hidden' : 'text-[#e6e9ed]'}">

                      ${Varobjectives}
              </div>
              <div class="mb-64">
                      <p class="text-[#3D92CC] mb-6 text-caps-2  leading-normal" >${VarResults === ' ' ? " " : "Resultados"}</p>
                      <hr class="${VarResults === ' ' ? 'hidden' : 'text-[#e6e9ed]'}">

                      
                        ${VarResults}
                      

                      
              </div>
              
              </div>
              
      </div>

      <div class="absolute md:hidden img_modal  top-1/2 -translate-y-1/2 right-0  ">

              <div class=" dimencionImgCard  z-0  overflow-hidden max-h-[823px]">
              <div class="swiper-cases">
                      <div class="swiper-wrapper">
                     

                      <div class="swiper-slide overflow-hidden">
                              <div class="before-degrade-imagem  overflow-hidden">
                              <img loading="lazy" class="w-full h-full object-cover" src="${data.image}" alt="Imagem fundadora 01">
                              </div>			
                              
                      </div>

                       <div class="swiper-slide overflow-hidden">
                              <div class="before-degrade-imagem  overflow-hidden">
                              <img loading="lazy" class="w-full h-full object-cover" src="${data.image}" alt="Imagem fundadora 01">
                              </div>			
                              
                      </div>

                       <div class="swiper-slide overflow-hidden">
                              <div class="before-degrade-imagem  overflow-hidden">
                              <img loading="lazy" class="w-full h-full object-cover" src="${data.image}" alt="Imagem fundadora 01">
                              </div>			
                              
                      </div>
                     

                      </div>

              </div>

              

              </div>
              
      

      </div>


      <div class="absolute  md:hidden  right-0 clasbotaoajustedireita  mr-[4.5%]  top-1/2 -translate-y-1/2 ">
                      
              <div class="relative z-10 flex-col gap-24 mt-32 ">

              
              <button id="scrollLeftBtnAbout_modal_casses">
                      <svg class="w-[22px] h-[22px] " viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                      <path d="M12.833 7.33341L9.16634 11.0001L12.833 14.6667" stroke="currentColor" stroke-opacity="0.7" stroke-linecap="round" stroke-linejoin="round"/>
                                              </svg>
              
                              
              </button>

              <div class="py-8">
                      <div class="swiper-pagination_about"></div>
              </div>
              

              <button id="scrollRightBtnAbout_modal_casses">
                      <svg class="w-[22px] h-[22px] rotate-180 " viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                      <path d="M12.833 7.33341L9.16634 11.0001L12.833 14.6667" stroke="currentColor" stroke-opacity="0.7" stroke-linecap="round" stroke-linejoin="round"/>
                                              </svg>
                              
              </button>

              

              

                      
                      

              

              </div>

              

              

      </div>



     
      


`

                                                modal.querySelector('.modal-content').innerHTML = conteudoModal


                                              
                                                
                                                        
                                                loading.style.display= 'none'
                           

				modalCases()

				// const swiperCrrosselCases = new Swiper(".swiper-cases", {
				//         slidesPerView: 1,
				//         spaceBetween: 2,
				//         slideToClickedSlide:false,
				//         centeredSlides: false,

				//         navigation: {
				//                 nextEl: '#scrollLeftBtnAbout_modal_casses',
				//                 prevEl: '#scrollRightBtnAbout_modal_casses',
				//         },

				// });
			})
			.catch((error) => {
				console.error('Erro ao buscar dados:', error)
			})

		bodyElement.style.overflow = 'hidden'
		htmlElement.style.overflow = 'hidden'
	}
	const destinos = findAll('.js-destino')

	destinos.forEach((item) => {
		const idDestino = item.dataset.destino
		ids.push(idDestino)
		item.addEventListener('click', () => {
			const idDestino = item.dataset.destino

			

			const modal_conteudo = document.getElementById('myModal')
			const bodyElement = document.querySelector('body')
			const htmlElement = document.documentElement

			modal_conteudo.dataset.current = idDestino

			modal_conteudo.style.display = 'block'
			modal_conteudo.scrollTop = 0

                        getModalInfo(idDestino)
		})
	})

	modal.addEventListener('click', (e) => {
		const target = e.target
		const btnRight = target.closest('#scrollRightBtnAbout_modal_casses')
		const btnleft = target.closest('#scrollLeftBtnAbout_modal_casses')
		const modalCurrentId = modal.dataset.current

		const currentIndex = ids.indexOf(modalCurrentId)

		console.log(ids)

		console.log(modalCurrentId)
		console.log(currentIndex)
		if (btnleft) {
			if (currentIndex > 0) {
				modal.dataset.current = ids[currentIndex - 1]
				console.log('FETCH LEFT ID ', ids[currentIndex - 1])
                                getModalInfo(modal.dataset.current)
                                
			}else{
                                btnleft.style.opacity = 0.4
                        }

			return
		} else if (btnRight) {
			if (currentIndex < ids.length - 1) {
				modal.dataset.current = ids[currentIndex + 1]
				getModalInfo(modal.dataset.current)
			}else{
                                btnRight.style.opacity = 0.4
                        }
			return
		}
		return
	})
}
