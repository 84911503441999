import Swiper from "swiper"
import { find } from "utils/dom"

export default function () {
	const metodologia = find("[js-page='metodologia']")
	if (!metodologia) return


   
	


	const swiperCrrossel0 = new Swiper(".swiperCarrossel0", {
		slidesPerView: 1, // Número de cards visíveis ao mesmo tempo
		spaceBetween: 2,
		slideToClickedSlide:false,
		centeredSlides: false,

		pagination: {
			el: ".swiper-pagination3",
			type: "fraction",
	    },

		

	});

	const swiperCarrosselMetodologia = new Swiper(".swiperCarrosselMetodologia", {
        slidesPerView: 1, // Número de cards visíveis ao mesmo tempo
        spaceBetween: 35,
        slideToClickedSlide:false,
        autoHeight: true,
        centeredSlides: true,

        // speed: 1500, 
		
		// 		autoplay: {
		// 			delay: 10, 
		// 			pauseOnMouseEnter: true,
		// 			disableOnInteraction: false,
		// 		},

    });

    const slide_metodologia_1 = document.querySelector(".slide-metodologia-1").classList.add('active_metodologia')


        const buttons = document.querySelectorAll('.slide-metodologia-buttons button');
        buttons.forEach((button) => {
        button.addEventListener('click', () => {
        
            buttons.forEach((otherButton) => {
            otherButton.classList.remove('active_metodologia');
            });

            
            button.classList.add('active_metodologia');
        });
        });

    

        

        document
        .querySelector('.slide-metodologia-1')
        .addEventListener('click', function (e) {
            e.preventDefault();
            console.log("teste")
            swiperCarrosselMetodologia.slideTo(0,0);
        });

        document
        .querySelector('.slide-metodologia-2')
        .addEventListener('click', function (e) {
            e.preventDefault();
            console.log("teste")
            swiperCarrosselMetodologia.slideTo(1,0);
        });
        document
            .querySelector('.slide-metodologia-3')
            .addEventListener('click', function (e) {
                e.preventDefault();
                swiperCarrosselMetodologia.slideTo(2,0);
        });

       

        



}