import axios from 'axios'
import axiosConfig from './modules/axiosConfig';
import PageHome from './pages/Home.js';
import PageAbout from './pages/about.js';
import PageLogin from "./pages/Login";
import PageMetodologia from "./pages/metodologia";
import PageCases from "./pages/cases";
import PageDepoimentos from "./pages/depoimento";
import UseMouseOrKeyboard from "./modules/UseMouseOrKeyboard";
import DesignSystem from "./DesignSystem/DesignSystem";
import { register } from 'swiper/element/bundle';
import logs from "./utils/logs";
import ripple from "./modules/ripple";
import mottaEquipeText from "./modules/converteditorjs";
import formErros from "./modules/formErros";
import animaAoScroll from "./animation/animaAoScroll";
import animateSetup from "./animation/animateSetup";
import contatoForm from "./modules/contato-conectese";
import contatoFormNewsletter from "./modules/newsletter";
import contatoFormNewsletter_mobile from "./modules/newsletter_mobile"
// import SvgUse from "./utils/SvgUse";

console.log("teste Blog")

import Swiper from 'swiper';
import autoprefixer from 'autoprefixer';

// Init
GLOBAL.DesignSystem = DesignSystem({})
register() // Swiper Web Component

// Config
axiosConfig({ csrf: GLOBAL.context?.csrf }, axios)
UseMouseOrKeyboard()
ripple()
formErros()
animateSetup()
animaAoScroll()
mottaEquipeText()
contatoForm()
contatoFormNewsletter()
contatoFormNewsletter_mobile()

// Pages
PageHome()
PageAbout()
PageCases()
PageDepoimentos()
PageMetodologia()
PageLogin()?.init()

// utils logs
logs()



document.getElementById('toggleButton').addEventListener('click', function() {
	var meuNav = document.getElementById('meuNav');
	let navMenuList = document.querySelector(".navMenuList")
	let ornamentoClass = document.querySelector(".ornamentoClass")
	if (meuNav.style.height === '60%') {
		meuNav.style.height = '8%';
		
		navMenuList.classList.add('hidden');
		ornamentoClass.classList.add('hidden')
	} else {
		meuNav.style.height = '60%';
		
		navMenuList.classList.remove('hidden');
		ornamentoClass.classList.remove('hidden')
		
	}
});


function contarClasses(classe) {
    const elementos = document.getElementsByClassName(classe);
	const hiddenitensfour = document.querySelector(".hiddenitensfour")
	

	
	if (elementos.length === 4) {
		hiddenitensfour.classList.add("hidden")
		
	}
}

contarClasses('countDisappearElement');

function contarClassesAbout(classe) {
    const elementos_about = document.getElementsByClassName(classe);
	const hiddenitensfour_about = document.querySelector(".hiddenitensfour_about")

	
	if (elementos_about.length === 4) {
		hiddenitensfour_about.classList.add("hidden")
	}
}

contarClassesAbout('countDisappearElementAbout');










document.addEventListener('DOMContentLoaded', function() {

	
	const currentPath = window.location.pathname.replace(/\/$/, ''); 
	const menuItems = document.querySelectorAll('.menu-item');


	//console.log("Aqui estou", currentPath, menuItems)

	menuItems.forEach(item => {
		const itemLink = item.getAttribute('data-link').replace(/\/$/, ''); 

		console.log("EU",itemLink)
		if (currentPath === itemLink) {
			item.classList.add('active_menu_pesquise');
		}
	});
});



const linkPagination = document.querySelectorAll('.page-list a');
	const urlparams = new URLSearchParams(window.location.search)



	

	if (urlparams ) {

		const categoria = urlparams.getAll("categoria")
		if (categoria.length > 0) {
			linkPagination.forEach((link) => {
				let newUrl = link.getAttribute("href").replace("?","&")
				
				
					newUrl += `&categoria=${categoria.join("&categoria=")}`
				
				link.href = `?${newUrl}`
	
				
	
	
	
				
		});

		}
		

	}



// console.log(teste)







const swiperCrrossel_fundamentos = new Swiper(".swiperCarrossel_fundamentos", {
	slidesPerView: 4,
	spaceBetween: 2,
	slideToClickedSlide:false,
	preloadImages: false,
	noSwiping:true,
	a11y: false,
	
	centeredSlides: false,
	autoplay: false,

	pagination: {
		el: window.innerWidth <= 1024 ? ".swiper-pagination-fundamentos" : undefined,
		type: "fraction",
    },
	
	navigation: {
		nextEl: '#scrollRightBtn_fundamentos', 
		prevEl: '#scrollLeftBtn_fundamentos', 
  },


	breakpoints: {
		
		2220: {
			slidesPerView: 1.2,
			centeredSlides: false,
			spaceBetween: 2,
		},
		420: {
			slidesPerView: 1.5,
			centeredSlides: false,
			spaceBetween: 2,
		},
		640: {
			slidesPerView: 1.6,
			centeredSlides: false,
			spaceBetween: 2,
		},
		820: {
			slidesPerView: 3,
			centeredSlides: false,
			spaceBetween: 2,
			
			
		},
		1024: {
			slidesPerView: 4,
			centeredSlides: false,
			spaceBetween: 2,

			pagination: {
				el: ".swiper-pagination1",
				type: "fraction",
			  },
			
		  },
	}

	



});

function contarElementosComClasse(classe) {
	const elementos = document.getElementsByClassName(classe);
	return elementos.length;
}


console.log("Aqui estou", )













		
			
		




			
